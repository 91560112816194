import { create, useStore } from "zustand";

export const userDetailsStore = create((set) => ({
  isLoading: false,
  username: "",
  referralTelegramId: "",
  totalCredits: "",
  isFarming: "",
  level: {
    category: "",
    level: 0,
    mascot: "",
  },
  wallet: null
}));

export const useUserDetailsStore = () => useStore(userDetailsStore);

import {
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Text,
  Heading,
  Divider,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usersService } from "services/usersService";
import { useUserDetailsStore } from "store/useUserDetailsStore";
import { useClipboard } from "@chakra-ui/react";

export const UserDetailsView = () => {
  const {
    username,
    referralTelegramId,
    totalCredits,
    isFarming,
    level,
    isLoading,
    wallet,
  } = useUserDetailsStore();

  const { userID } = useParams();
  const navigate = useNavigate();

  // Clipboard hooks for wallet address and wallet memo
  const { hasCopied: hasCopiedAddress, onCopy: onCopyAddress } = useClipboard(
    wallet?.address || ""
  );
  const { hasCopied: hasCopiedMemo, onCopy: onCopyMemo } = useClipboard(
    wallet?.memo || ""
  );

  useEffect(() => {
    if (userID) {
      usersService.getUser(userID);
    }
  }, [userID]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" color="teal.500" />
      </Flex>
    );
  }

  if (!userID) {
    return null;
  }

  // Custom Card Component with Shadow and Padding
  return (
    <Box p={6} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <Flex direction="column" gap={6}>
        {/* Page Header */}
        <Heading as="h1" fontSize="3xl" fontWeight="bold" textAlign="center">
          User Details
        </Heading>

        {/* User Info Card */}
        <Box shadow="md" borderWidth="1px" borderRadius="lg" p={6}>
          <Flex direction="column" gap={4}>
            {/* Mascot Image */}
            {level?.mascot && (
              <Image
                src={level.mascot}
                borderRadius="full"
                boxSize="150px"
                alt="Mascot"
                mx="auto"
              />
            )}

            {/* Username */}
            <Flex justifyContent="center" alignItems="center" gap={5} mt={4}>
              <Text fontSize="lg" fontWeight="bold">
                Username:
              </Text>
              <Text fontSize="lg">{username || "N/A"}</Text>
            </Flex>

            {/* Telegram ID */}
            <Flex justifyContent="center" alignItems="center" gap={5}>
              <Text fontSize="lg" fontWeight="bold">
                Referral Telegram ID:
              </Text>
              <Text fontSize="lg">{referralTelegramId || "N/A"}</Text>
            </Flex>

            {/* Total Credits */}
            <Flex justifyContent="center" alignItems="center" gap={5}>
              <Text fontSize="lg" fontWeight="bold">
                Total Credits:
              </Text>
              <Text fontSize="lg">{totalCredits || 0}</Text>
            </Flex>
          </Flex>
        </Box>

        {/* Activity Info */}
        <Box shadow="md" borderWidth="1px" borderRadius="lg" p={6}>
          <Flex direction="column" gap={4}>
            {/* Farming Status */}
            <Flex justifyContent="center" alignItems="center" gap={5}>
              <Text fontSize="lg" fontWeight="bold">
                Farming Status:
              </Text>
              <Text fontSize="lg">{isFarming ? "Yes" : "No"}</Text>
            </Flex>

            {/* Level */}
            <Flex justifyContent="center" alignItems="center" gap={5}>
              <Text fontSize="lg" fontWeight="bold">
                Level:
              </Text>
              <Text fontSize="lg">{level?.level || "N/A"}</Text>
            </Flex>

            {/* Category */}
            <Flex justifyContent="center" alignItems="center" gap={5}>
              <Text fontSize="lg" fontWeight="bold">
                Category:
              </Text>
              <Text fontSize="lg">{level?.category || "N/A"}</Text>
            </Flex>
          </Flex>
        </Box>

        {/* Wallet Info */}
        <Box shadow="md" borderWidth="1px" borderRadius="lg" p={6}>
          <Flex direction="column" gap={4}>
            {/* Check if wallet is null */}
            {!wallet ? (
              <Heading as="h2" fontSize="xl" fontWeight="bold" textAlign="center">
                Wallet not connected
              </Heading>
            ) : (
              <>
                {/* Wallet Address (with Copy Functionality) */}
                <Flex justifyContent="center" alignItems="center" gap={4}>
                  <Text fontSize="lg" fontWeight="bold">
                    Wallet Address:
                  </Text>
                  <Text fontSize="lg">{wallet?.address || "N/A"}</Text>
                  {wallet?.address && (
                    <Tooltip
                      label={hasCopiedAddress ? "Copied!" : "Copy to clipboard"}
                      closeOnClick={false}
                    >
                      <IconButton
                        aria-label="Copy Wallet Address"
                        icon={<CopyIcon />}
                        size="sm"
                        color="black"
                        onClick={onCopyAddress}
                      />
                    </Tooltip>
                  )}
                </Flex>

                {/* Wallet Provider */}
                <Flex justifyContent="center" alignItems="center" gap={5}>
                  <Text fontSize="lg" fontWeight="bold">
                    Wallet Provider:
                  </Text>
                  <Text fontSize="lg">{wallet?.provider || "N/A"}</Text>
                </Flex>

                {/* Wallet Memo (with Copy Functionality) */}
                <Flex justifyContent="center" alignItems="center" gap={4}>
                  <Text fontSize="lg" fontWeight="bold">
                    Wallet Memo:
                  </Text>
                  <Text fontSize="lg">{wallet?.memo || "N/A"}</Text>
                  {wallet?.memo && (
                    <Tooltip
                      label={hasCopiedMemo ? "Copied!" : "Copy to clipboard"}
                      closeOnClick={false}
                    >
                      <IconButton
                        aria-label="Copy Wallet Memo"
                        icon={<CopyIcon />}
                        size="sm"
                        color="black"
                        onClick={onCopyMemo}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Box>

        <Divider my={4} />
      </Flex>
    </Box>
  );
};